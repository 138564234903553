<template>
  <div class="oxyy-login-register" style="max-width:600px; width:100%; margin: 0 auto">
    <div class="bg-white shadow-md is-rounded p-4 mt-4">
      <div class="logo is-flex is-justify-content-center">
        <img width="300" :src="`logos/${site}/logo.svg`" />
      </div>
      <hr>
      <div autocomplete="off" @keypress.enter="login">

        <template v-if="!qr && !is2fa">
        <div class="mb-3">
          <label for="username" class="form-label">Kullanıcı Adı</label>
          <input type="text" class="form-control" id="username" placeholder="Kullanıcı Adı" v-model="form.username">
        </div>
        <div class="mb-3">
          <label for="loginPassword" class="form-label">Parola</label>
          <input type="password" class="form-control" id="loginPassword" placeholder="******" v-model="form.password">
        </div>
        </template>

        <template>
          <div v-html="qr" v-if="qr"></div>
          <div class="input-login app-input" v-if="qr || is2fa">
            <input ref="qrInput" type="text" name="code" id="code" v-model="form.code" placeholder="Google Authenticator Kodunu Giriniz"/>
          </div>
        </template>
        <div class="is-full my-4">
          <button class="button btn" type="button" @click="login">Login</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

import { http } from '@/utils/axios'
export default {
  name: 'Login',
  data() {
    return {
      form: {
        username: '',
        password: '',
        code: null
      },
      qr: '',
      is2fa: false
    }
  },
  methods: {
    login() {
      http.post('/auth/login', this.form)
        .then(async res => {
          if (res.data.qr) {
            this.qr = res.data.qr
            return
          }
          if (res.data.user && res.data.user.is2fa) {
            this.is2fa = res.data.user.is2fa
            setTimeout(() => {
              this.$refs.qrInput.focus()
            }, 1)
            return
          }
          if (res.data.user && res.data.user.accessToken) {
            await this.$store.dispatch('setBasic', { key: 'isLoggedIn', value: 1 })
            await this.$store.dispatch('setBasic', { key: 'user', value: { ...res.data.user } })
            http.defaults.headers['Authorization'] = await `${res.data.user.tokenType} ${res.data.user.accessToken}`
            if (this.$route.query.redirect)
              this.$router.push(this.$route.query.redirect)
            else
              this.$router.push({ name: 'home' })
          }
        })
        .catch(err => this.$buefy.toast.open({ message: err.message, position: 'is-top-right', type: 'is-danger' }))
    }
  }
}
</script>

<style lang="scss">
*,
*::before,
*::after {
  box-sizing: border-box;
}

:root {
  --purple-100: #ede9fe;
  --purple-200: #c4b5fd;
  --purple-600: #7c3aed;
  --purple-800: #5b21b6;
  --pink-100: #fee2e2;
  --pink-200: #fecaca;
  --grey-100: #f3f4f6;
  --grey-200: #e5e7eb;
  --grey-300: #d1d5db;
  --grey-400: #9ca3af;
  --grey-500: #6b7280;
  --grey-700: #374151;
  --white: #fff;
}

.login-wrapper {
  position: fixed;
  top: 0;
  left: 0;
  margin: 0;
  width: 100%;
  display: flex;
  height: 100vh;
  align-items: flex-start;
  justify-content: center;
  background-color: var(--grey-100);
  font-family: "Poppins", sans-serif;
  color: var(--grey-700);
}

.container {
  top: 60px;
  position: fixed;
  display: flex;
  flex-direction: column;
  width: 360px;
  height: 660px;
  border-radius: 24px;
  padding: 24px;
  background-color: var(--white);
  overflow: hidden;
  box-shadow: 0 0 #0000, 0 0 #0000, 0 4px 6px -1px rgb(0 0 0 / 10%),
    0 2px 4px -1px rgb(0 0 0 / 6%);
}

.header {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.header-title {
  font-size: 24px;
  font-weight: 500;
  margin-top: 0;
  margin-bottom: 8px;
}

.header-image {
  height: 80px;
  width: 80px;
  margin: 20px 40px 60px 40px;
}

.header-image svg {
  position: relative;
  fill: var(--purple-600);
}

.header-image-particle {
  position: absolute;
  border-radius: 50%;
  background-color: var(--pink-200);
  opacity: 0.8;
}

.header-image-particle-1 {
  height: 22px;
  width: 22px;
  animation: particle-1 1.2s ease-in-out alternate infinite;
}

@keyframes particle-1 {
  0% {
    transform: translate3d(80px, 65px, 0);
  }

  100% {
    transform: translate3d(88px, 73px, 0);
  }
}

.header-image-particle-2 {
  height: 18px;
  width: 18px;
  animation: particle-2 1.4s ease-in-out alternate infinite;
}

@keyframes particle-2 {
  0% {
    transform: translate3d(-50px, 40px, 0);
  }

  100% {
    transform: translate3d(-30px, 40px, 0);
  }
}

.header-image-particle-3 {
  height: 12px;
  width: 12px;
  animation: particle-3 1.8s ease-in-out alternate infinite;
}

@keyframes particle-3 {
  0% {
    transform: translate3d(90px, 10px, 0);
  }

  100% {
    transform: translate3d(105px, -5px, 0);
  }
}

.button {
  width: auto !important;
  padding: 16px 16px;
  // margin: 16px 0;
  border-radius: 8px;
  border: none;
  font-weight: 500;
  font-size: 16px;
  color: var(--white);
  background-color: var(--purple-600);
  -webkit-appearance: none;
  cursor: pointer;
  font-family: inherit;
  transition: background-color 200ms, color 200ms;
}

.button:active {
  background-color: var(--purple-800);
}

.link {
  color: var(--purple-600);
  font-weight: 500;
  text-decoration: none;
}

.link:hover {
  text-decoration: underline;
}

.text {
  font-size: 14px;
  line-height: 22px;
  margin-top: 0;
  margin-bottom: 16px;
}

.text:not(.link) {
  color: var(--grey-500);
}

.text-center {
  text-align: center;
}

.modal,
.modal-backdrop {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}

.modal {
  display: flex;
  justify-content: center;
  align-items: flex-end;
  pointer-events: none;
}

.modal-active.modal {
  pointer-events: auto;
}

.modal-backdrop {
  opacity: 0;
  background-color: rgba(8, 2, 29, 0.5);
  transform: translateZ(0);
  transition: opacity 200ms;
}

.modal-active .modal-backdrop {
  opacity: 1;
}

.modal-body {
  position: relative;
  display: flex;
  align-items: center;
  flex-direction: column;
  border-top-right-radius: 24px;
  border-top-left-radius: 24px;
  width: 100%;
  padding: 24px;
  background: var(--white);
  transform: translateY(100%);
  transition: transform 220ms cubic-bezier(0.33, 1, 0.68, 1);
}

.modal-body::before {
  content: "";
  position: absolute;
  top: 14px;
  height: 4px;
  margin: 0 auto;
  width: 60px;
  border-radius: 2px;
  background-color: var(--grey-200);
}

.modal-active .modal-body {
  transform: translateY(0);
}

.modal-title {
  margin-top: 8px;
  margin-bottom: 16px;
  font-weight: 500;
  font-size: 20px;
}

.settings-buttons {
  display: flex;
  justify-content: space-around;
  width: 100%;
}

.settings-button {
  min-width: 72px;
  padding: 10px 16px;
  margin: 16px 0;
  border-radius: 16px;
  border: none;
  font-weight: 700;
  font-size: 12px;
  color: var(--grey-500);
  background-color: var(--grey-100);
  -webkit-appearance: none;
  cursor: pointer;
  font-family: inherit;
  transition: background-color 200ms, color 200ms;
}

.settings-button-active {
  background-color: var(--pink-100);
  color: var(--purple-600);
}

.settings-button-icon {
  font-size: 40px;
  text-align: center;
  color: var(--grey-300);
  transform: translate3d(0, 0, 0);
  transition: color 200ms;
}

.settings-button-icon-active {
  color: var(--purple-600);
  transform-origin: center center;
  animation: bounce 500ms ease-in-out;
}

@keyframes bounce {
  0% {
    transform: scale3d(1, 1, 1) translate3d(0, 0, 0);
  }

  10% {
    transform: scale3d(1, 0.8, 1) translate3d(0, 0, 0);
  }

  40% {
    transform: scale3d(1, 1, 1) translate3d(0, -12px, 0);
  }

  70% {
    transform: scale3d(1, 1, 1) translate3d(0, 0, 0);
  }

  100% {
    transform: scale3d(1, 1, 1) translate3d(0, 0, 0);
  }
}

.input-login {
  position: relative;
  margin: 16px 0;
  font-size: 16px;
}

.input-login label {
  position: absolute;
  top: 16px;
  left: 20px;
  font-weight: 500;
  color: var(--grey-400);
  pointer-events: none;
  transform-origin: top left;
  transition: transform 200ms;
}

.input-login input {
  padding: 20px;
  width: 100%;
  outline: none;
  border: none;
  font-size: 16px;
  border-radius: 8px;
  background: var(--grey-100);
  transition: background-color 160ms;
}

.input-login input:focus {
  background: var(--grey-200);
}

.input-active label {
  transform: scale(0.7, 0.7) translate(0, -10px);
}

.footer {
  display: flex;
  justify-content: center;
  align-items: flex-end;
  flex-grow: 1;
}

.footer .text {
  margin: 0;
}

.oxyy-login-register .shadow-md {
  -webkit-box-shadow: 0px 0px 50px -35px rgba(0, 0, 0, 0.4) !important;
  box-shadow: 0px 0px 50px -35px rgba(0, 0, 0, 0.4) !important;
}

.bg-white {
  --bs-bg-opacity: 1;
  background-color: #fff;
}


.oxyy-login-register .form-control:not(.form-control-sm) {
  padding: 0.81rem 0.96rem;
  height: inherit;
}

.oxyy-login-register .form-control {
  border-color: #dae1e3;
  font-size: 16px;
  color: #656565;
  display: block;
  width: 100%;
  padding: .375rem .75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #212529;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
    border-top-color: rgb(206, 212, 218);
    border-right-color: rgb(206, 212, 218);
    border-bottom-color: rgb(206, 212, 218);
    border-left-color: rgb(206, 212, 218);
  appearance: none;
  border-radius: .25rem;
  transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out;
}

.oxyy-login-register .btn {
  padding: 0.8rem 2.6rem;
  width: 100%!important;
  font-weight: 500;
  -webkit-transition: all 0.5s ease;
  transition: all 0.5s ease;
}
</style>
